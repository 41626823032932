import { defineStore } from 'pinia';

export const filterNews = defineStore({
    id: 'filterNews',

    state: () => ({
        filterType: 'all',
        filterStartDate: null,
        filterEndDate: null,
        currentPage: 1,
    }),

    actions: {
        setFilterType(payload) {
            this.filterType = payload;
        },
        setFilterStartDate(payload) {
            this.filterStartDate = payload;
        },
        setFilterEndDate(payload) {
            this.filterEndDate = payload;
        },
        formatInputDate(inputDate) {
            const day = String(inputDate.getUTCDate()).padStart(2, '0');
            const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
            const year = inputDate.getUTCFullYear();
            return `${day}.${month}.${year}`;
        },
        formatFilterDate(inputDate) {
            const date = new Date(inputDate);
            return !isNaN(date) ? date.toISOString().split('T')[0] : undefined;
        },
        formatNewsDate(inputDate, todayText) {
            const date = new Date(inputDate);
            const today = new Date();
            return date.toDateString() === today.toDateString() ? todayText : this.formatInputDate(date);
        },
        getLangForUrl: lang => {
            return lang === 'kk' ? '' : lang + '/';
        },
    },
    persist: true,
});
